<template>
  <div v-if="data">
    <!-- 模式 -->
    <v-radio-group
      v-if="multiInstances"
      v-model="instanceMode"
      row
    >
      <v-radio
        v-for="mode in ['simple', 'edit']"
        :key="mode"
        :label="$t(`product.instance.${mode}_model`)"
        :value="mode"
      ></v-radio>
    </v-radio-group>

    <v-draggable
      handle=".product-instance-draggable"
      v-model="data"
    >
      <productInstance
        v-for="(instance, instanceIndex) in data"
        :multiInstances="multiInstances"
        :key="instanceIndex"
        :index="instanceIndex"
        :mode="instanceMode"
        :quantity="data.length"
        @remove="removeInstance"
        :instance.sync="instance"
      ></productInstance>
    </v-draggable>
  </div>
</template>

<script lang="babel" type="text/babel">
import formItemMixin from '@/components/form/mixins/formItemMixin'
export default {
  mixins: [formItemMixin],
  data: () => ({
    instanceMode: 'simple',
  }),
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if(!this.multiInstances) {
        this.instanceMode = 'edit'
      }
      if(!Array.isArray(this.storeData)) {
        this.data = []
        return
      }

      this.data = window.eagleLodash.cloneDeep(this.storeData)
    },
    removeInstance(index) {
      this.$delete(this.data, index)
    },
  },
  computed: {
    multiInstances() {
      if(!this.formData) return false
      return this.formData.multi_instances == true
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        if(window.eagleLodash.isEqual(this.data, this.storeData)) return
        this.sync()
      },
    },
    storeData: {
      deep: true,
      handler() {
        if(window.eagleLodash.isEqual(this.data, this.storeData)) return
        this.init()
      },
    },
  },
  components: {
    productInstance: () => import('./productInstance.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
