<template>
  <div v-if="data">
    <div
      v-for="(config, configIndex) in data"
      :key="configIndex"
    >
      <span class="font-weight-bold">
        {{config.name}}
      </span>
      <span>
        : {{attributesArrayToString(config.attributes)}}
      </span>
    </div>

    <v-btn
      color="primary"
      class="my-4"
      outlined
      @click="setupInstanceConfig"
    >
      {{'product.action.setup_instance_config'| t}}
    </v-btn>
  </div>
</template>

<script lang="babel" type="text/babel">
import formItemMixin from '@/components/form/mixins/formItemMixin'
import instances from "@/modules/product/views/productForm/instances";
export default {
  mixins: [formItemMixin],
  mounted() {
    this.init()
  },
  methods: {
    setupInstanceConfig() {
      this.$apopup.base({
        title: this.$t('product.action.setup_instance_config'),
        width: '550px',
        bodySlot: () => import('modules/product/components/productInstanceConfigPopup.vue'),
        applyOnEnter: false,
        defaultValue: window.eagleLodash.cloneDeep(this.data),
        disabledApply: data => {
          if(!data) return true
          if(!Array.isArray(data)) return true
          if(data.length == 0) return true
          return false
        },
        applyCallback: instanceConfig => {
          this.data = instanceConfig
          this.setInstances(instanceConfig)
        },
      })
    },
    setInstances(instanceConfig) {
      const instances = this.$helper.createProductInstanceSets(instanceConfig)
      let result = []
      for(const instanceItem of instances) {
        const storeInstanceItem = this.getProductInstanceItemByName(instanceItem.name)
        if(storeInstanceItem) {
          result.push(storeInstanceItem)
        }

        else {
          result.push(instanceItem)
        }
      }

      this.$store.dispatch(`form/${this.formKey}/setDataColumn`, {
        key: 'instances',
        value: result,
      })
    },
    getProductInstanceItemByName(name) {
      const item = this.productInstances.find(instance => instance.name == name)
      if(!item) return null
      return window.eagleLodash.cloneDeep(item)
    },
    attributesArrayToString(attributes) {
      if(!Array.isArray(attributes)) return null
      return attributes.join(' / ')
    },
    init() {
      if(!Array.isArray(this.storeData)) {
        this.data = null
        return
      }

      this.data = window.eagleLodash.cloneDeep(this.storeData)
    },
  },
  computed: {
    productInstances() {
      if(!this.formData) return []
      return this.formData.instances
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        if(window.eagleLodash.isEqual(this.data, this.storeData)) return
        this.sync()
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>